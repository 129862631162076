import { useMutation } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { CHECK_PROCESSING_CONFIRMING, CONFIRMINGS_QUERY } from '@fingo/lib/graphql';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import React from 'react';

const ProcessConfirmingDialog = () => {
  const [openDialog, toggleDialog,, closeDialog] = useBooleanState();
  const { addAlert } = useSnackBars();

  const [
    rejectMoneyTransfers,
    rejectMoneyTransfersResult,
  ] = useMutation(CHECK_PROCESSING_CONFIRMING, {
    onCompleted: () => {
      addAlert({
        severity: 'success',
        message: 'Confirmings procesados',
      });
      closeDialog();
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: 'Error al procesar Confirmings',
      });
    },
    refetchQueries: [CONFIRMINGS_QUERY],
  });

  return (
    <>
      <Button
        variant="contained"
        size="small"
        disableElevation
        onClick={toggleDialog}
        id="toggle-download-portfolio"
      >
        Procesar Confirmings
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={closeDialog}
        maxWidth="xs"
        fullWidth
        title="¿Confirmar Confirmings?"
      >
        <Box
          justifyContent="right"
          display="flex"
        >
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            id="toggle-download-portfolio"
            onClick={rejectMoneyTransfers}
            loading={rejectMoneyTransfersResult.loading}
          >
            Confirmar
          </LoadingButton>
        </Box>
      </FingoDialog>
    </>
  );
};

export default ProcessConfirmingDialog;
