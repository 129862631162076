import React from 'react';
import { Grid } from '@mui/material';
import ProcessConfirmingDialog from './ProcessConfirmingDialog';

const ConfirmingProcessingFlexButtons = () => (
  <Grid
    container
    columnSpacing={1}
    rowSpacing={1}
    sx={{
      bgcolor: 'background.light',
      borderRadius: 2,
      px: 1,
      ml: 0,
      py: 1,
      '& .MuiGrid-item:first-of-type': {
        pl: 0,
      },
    }}
    display="flex"
    alignItems="center"
    justifyContent="right"
  >
    <Grid item>
      <ProcessConfirmingDialog />
    </Grid>
  </Grid>
);

export default ConfirmingProcessingFlexButtons;
